import {Component, Input, OnInit} from '@angular/core';
import {IContent, IImage} from '../../model/interfaces';
import {rotate} from '../../util/gallery';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  @Input()
  public content: IContent;

  public activeImageIndex: number;
  public focusInfo: boolean;


  constructor() {
    this.activeImageIndex = 0;
    this.focusInfo = false;
  }

  public onClickToggleFocus(event: MouseEvent): void {
    console.log(this.hasDetails)
    console.log(this.focusInfo)
    this.focusInfo = !this.focusInfo && this.hasDetails;
  }

  public onClickNext(event: MouseEvent): void {
    this.prevent(event);
    this.activeImageIndex = rotate(this.activeImageIndex + 1, this.numberOfImages);
  }

  public onClickPrev(event: MouseEvent): void {
    this.prevent(event);
    this.activeImageIndex = rotate(this.activeImageIndex - 1, this.numberOfImages);

  }

  get activeImage(): IImage {
    return this.content.gallery.images[this.activeImageIndex];
  }


  get hasDetails(): boolean {
    const gallery = this.content.gallery;
    return gallery.description.length + gallery.links.length + gallery.credits.length > 0;
  }

  get numberOfImages(): number {
    return this.content.gallery.images.length;
  }

  ngOnInit() {
  }

  private prevent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
