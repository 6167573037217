import {NgModule} from '@angular/core';
import {IndexComponent} from './index.component';
import {MenuComponent} from '../../components/menu/menu.component';
import {CommonModule} from '@angular/common';
import {ContactComponent} from '../../components/contact/contact.component';
import {HttpClientModule} from '@angular/common/http';
import {ContentComponent} from '../../components/content/content.component';

import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';


@NgModule({
  declarations: [
    IndexComponent,
    MenuComponent,
    ContactComponent,
    ContentComponent,

  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ScrollToModule.forRoot(),
  ],
  exports: [
    IndexComponent
  ],

  providers: [],
})
export class IndexModule {
}
