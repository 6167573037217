import {Component, Input, OnInit} from '@angular/core';
import {IContent} from '../../model/interfaces';
import {ScrollToService, ScrollToConfigOptions} from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input()
  public content: IContent[];
  @Input()
  public title: string;
  isOpen = false;

  constructor(private scrollToService: ScrollToService) {
  }

  ngOnInit() {
    const hash = window.location.hash = '';
  }


  public onClickContact(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.isOpen = false;
    this.goto('#contact');
  }
  public onClick(event: MouseEvent, entry: IContent) {
    event.stopPropagation();
    event.preventDefault();
    this.isOpen = false;
    this.goto('#' + entry.reference);
  }

  private goto(reference) {
    const config: ScrollToConfigOptions = {
      target: reference
    };
    this.scrollToService.scrollTo(config).subscribe(() => {
      // @ts-ignore
      // window.location = ('' + window.location).replace(/#[A-Za-z0-9_]*$/, '') + reference;
      window.location.hash = reference;
    })
    ;
  }

  public toggleMenu() {
    this.isOpen = !this.isOpen;
  }

}
