import {Component, Input, OnInit} from '@angular/core';
import {IContact, IContent, IGallery, IPage} from '../../model/interfaces';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {


  public page: IPage;

  constructor(private http: HttpClient) {

  }

  ngOnInit() {
    this.http.get('/assets/content.json').subscribe((page: IPage) => {
      this.page = page;
      this.page.content.forEach(content => {
        const folder = content.folder;
        this.http.get('/assets/gallery/' + folder + '/' + folder + '.json').subscribe((response: IGallery) => {
          console.log(response);
          content.gallery = response;
        });
      });
    });


  }

  get currentYear(): string {
    const date = new Date();
    return '' + date.getFullYear();
  }
}
